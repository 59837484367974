import { IMAGE_HEIGHT, IMAGE_WIDTH } from './constants';
import { Bounds, hashToColor, vector2D, Vector2D } from './util';
import {
  ACTIVE,
  activeStates,
  AVAILABLE,
  BURNED,
  EVOLVE,
  evolvePlaceholders,
  SAFARI,
} from 'constants/index';
import ownedIcon from 'images/owned-icon.png';
import saleIcon from 'images/sale-icon.png';
import { NftData, NftDict } from '../../types';
import { useSelector } from 'react-redux';
import { getNftDictData } from '../../redux/slices/nft';
import { getUserAddress } from '../../redux/slices/application';
import { useCallback, useEffect, useRef } from 'react';
import { SquareStatus } from '../crypto/types';
import { getDisplayImage, isInBounds } from '../../helpers/squares';

export type ImageRenderer = {
  images: NftDict;
  origin: Vector2D;
  scale: number;
};

type LoadImage = NftData & {
  height: number;
  renderKey: number;
  scale: number;
  width: number;
};

type LoadImageData = {
  id: string;
  renderKey: number;
};

export type ClearImageCacheArgs = {
  coords: Vector2D[];
  maxGen: number;
};

export type DrawIcon = {
  owner?: string;
  saleAmount?: number;
  scale: number;
  status?: string;
  x: number;
  y: number;
};

export type RenderImageOrImageData = {
  height: number;
  id: string;
  status?: SquareStatus;
  width: number;
  x: number;
  y: number;
};

type CanvasCache = {
  bounds: Bounds;
  cache: ImageData;
  origin: Vector2D;
};

type GreySquareArgs = {
  status?: SquareStatus;
  x: number;
  y: number;
  width: number;
  height: number;
};

const ICON_GUTTER = 4;
const DEBUG_RENDER_CANVAS = false;

export const useImageRenderer = (
  ctx: CanvasRenderingContext2D | null,
  canvas: HTMLCanvasElement | null,
) => {
  const forceDrawOnNextRender = useRef<Record<string, boolean>>({});
  const imageCache = useRef<{ [id: string]: HTMLImageElement }>({});
  const imageDataCache = useRef<{ [id: string]: ImageData }>({});
  const renderKey = useRef<number>(Date.now());
  const canvasCacheRef = useRef<CanvasCache>();
  const renderCanvasCtxRef = useRef<CanvasRenderingContext2D>();
  const data = useSelector(getNftDictData);
  const userAddress = useSelector(getUserAddress);

  const preloadImage = useCallback(
    async (src: string) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          imageCache.current[src] = img;
          resolve(img);
        };
        img.src = src;
      }),
    [],
  );

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'render-canvas');
    canvas.style.position = 'absolute';
    canvas.style.backgroundColor = DEBUG_RENDER_CANVAS
      ? '#ff0000'
      : 'transparent';
    canvas.style.top = DEBUG_RENDER_CANVAS ? '0px' : '-10000px';
    canvas.style.left = DEBUG_RENDER_CANVAS ? '0px' : '-10000px';
    canvas.width = 1000;
    canvas.height = 1000;
    canvas.style.zIndex = DEBUG_RENDER_CANVAS ? '999999' : '-1';

    document.body.appendChild(canvas);
    const renderCtx = canvas.getContext('2d');
    if (renderCtx) {
      renderCanvasCtxRef.current = renderCtx;
    }
    return () => canvas.remove();
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([
        ...evolvePlaceholders.map(url => preloadImage(url)),
        preloadImage(saleIcon),
        preloadImage(ownedIcon),
      ]);
    })();
  }, []);

  const renderImageOrImageData = ({
    height,
    id,
    status,
    width,
    x,
    y,
  }: RenderImageOrImageData) => {
    if (ctx) {
      if (imageDataCache.current[id]) {
        ctx?.putImageData(imageDataCache.current[id], x, y);
      } else if (imageCache.current[id]) {
        ctx.drawImage(imageCache.current[id], x, y, width, height);
      }
      greySquare({
        x,
        y,
        width,
        height,
        status,
      });
    }
  };

  const cacheScreen = (screenBounds: Bounds, origin: Vector2D) => {
    // Modify the bounds to account for any gaps that can happen when getting the image data
    // if (navigator.vendor !== SAFARI) {
    //   console.log('Creating cache');
    //   const bounds: Bounds = {
    //     tl: vector2D(screenBounds.tl.x + 1, screenBounds.tl.y + 1),
    //     br: vector2D(screenBounds.br.x - 1, screenBounds.br.y - 1),
    //   };
    //
    //   const cacheImageData = ctx?.getImageData(
    //     0,
    //     0,
    //     canvas?.width ?? window.innerWidth,
    //     canvas?.height ?? window.innerHeight,
    //   );
    //
    //   if (cacheImageData) {
    //     canvasCacheRef.current = {
    //       bounds,
    //       cache: cacheImageData,
    //       origin,
    //     };
    //     forceDrawOnNextRender.current = {};
    //   }
    // }
  };

  const greySquare = ({ status, x, y, width, height }: GreySquareArgs) => {
    if (status && !activeStates.includes(status) && ctx) {
      ctx.fillStyle = '#333333D9';
      ctx.fillRect(x, y, width, height);
    }
  };

  const drawImages = ({ images, origin, scale }: ImageRenderer) => {
    renderKey.current = Date.now();

    if (!ctx) {
      return;
    }

    const coordsAdded: Record<string, boolean> = {};

    if (canvasCacheRef?.current) {
      const { x, y } = canvasCacheRef.current.origin;
      const resolvedX = (x - origin.x) * -1;
      const resolvedY = (y - origin.y) * -1;
      ctx?.putImageData(canvasCacheRef.current.cache, resolvedX, resolvedY);
    }

    Object.values(images).forEach(
      ({ gen = 1, id, owner, parent, saleAmount, status, x, y }) => {
        const baseWidth = IMAGE_WIDTH * scale;
        const baseHeight = IMAGE_HEIGHT * scale;
        const dWidth = baseWidth * 2 ** (parseInt(gen.toString()) - 1);
        const dHeight = baseHeight * 2 ** (parseInt(gen.toString()) - 1);
        const [parentX, parentY] =
          parent?.split(',').map(n => parseInt(n, 10)) ?? [];
        const _x = parentX ?? x ?? 0;
        const _y = parentY ?? y ?? 0;
        const _gen = parseInt(gen.toString());

        const dx = Math.round(_x * baseWidth + origin.x);
        const dy = Math.round(_y * baseHeight + origin.y);
        const resolvedCoords = `${_x},${_y}`;
        const image = data[resolvedCoords]?.image;
        const cacheId = `${resolvedCoords},${gen}`;

        if (!coordsAdded[resolvedCoords]) {
          const { bounds: cacheBounds } = canvasCacheRef?.current ?? {};
          const brIsInCache = isInBounds(
            vector2D(_x + _gen + 1, _y + _gen + 2),
            cacheBounds,
          );
          const tlIsInCache = isInBounds(vector2D(_x, _y), cacheBounds);

          const isInCache = brIsInCache && tlIsInCache;
          if (!isInCache || forceDrawOnNextRender.current[id]) {
            if (!gen && status !== ACTIVE) {
              ctx.fillStyle = `#bbbbbb`;
              ctx.fillRect(dx, dy, dWidth, dHeight);
            } else if (
              status === EVOLVE ||
              status === BURNED ||
              image === 'null'
            ) {
              const placeholderImage =
                imageCache?.current?.[evolvePlaceholders[_gen - 1]] ??
                imageCache?.current?.[evolvePlaceholders[1]];
              ctx.fillStyle = `#${hashToColor(id)}`;
              ctx.fillRect(dx, dy, dWidth, dHeight);
              ctx.drawImage(placeholderImage, dx, dy, dWidth, dHeight);
            } else if (
              imageCache.current[cacheId] ||
              imageDataCache.current[cacheId]
            ) {
              renderImageOrImageData({
                id: cacheId,
                x: dx,
                y: dy,
                width: dWidth,
                height: dHeight,
                status,
              });
              drawIcon({ owner, saleAmount, scale, status, x: dx, y: dy });
              coordsAdded[`${dx},${dy}`] = true;
            } else {
              ctx.fillStyle = `#${hashToColor(id)}`;
              ctx.fillRect(dx, dy, dWidth, dHeight);
              if (image) {
                loadImage({
                  height: dHeight,
                  id: cacheId,
                  image,
                  owner,
                  renderKey: renderKey.current,
                  saleAmount,
                  scale,
                  width: dWidth,
                  x,
                  y,
                }).then(({ id, renderKey: loadedRenderKey }) => {
                  forceDrawOnNextRender.current[id] = true;
                  if (renderKey?.current === loadedRenderKey) {
                    renderImageOrImageData({
                      id,
                      x: dx,
                      y: dy,
                      width: dWidth,
                      height: dHeight,
                      status,
                    });
                  }
                });
              }
            }
            if (forceDrawOnNextRender.current[id]) {
              delete forceDrawOnNextRender.current[id];
            }
          }
        }
      },
    );
  };

  const drawIcon = ({ owner, saleAmount, scale, status, x, y }: DrawIcon) => {
    if (owner === userAddress && imageCache.current[ownedIcon]) {
      renderCanvasCtxRef.current?.drawImage(
        imageCache.current[ownedIcon],
        x + ICON_GUTTER,
        y + ICON_GUTTER,
        imageCache?.current?.[ownedIcon].width * scale,
        imageCache?.current?.[ownedIcon].height * scale,
      );
    } else if (
      saleAmount &&
      (!status || status === ACTIVE) &&
      imageCache.current[saleIcon]
    ) {
      renderCanvasCtxRef.current?.drawImage(
        imageCache.current[saleIcon],
        x + ICON_GUTTER,
        y + ICON_GUTTER,
        imageCache.current[saleIcon].width * scale,
        imageCache.current[saleIcon].height * scale,
      );
    }
  };

  const loadImage = ({
    height,
    id,
    image,
    owner,
    renderKey,
    saleAmount,
    scale,
    status,
    width,
  }: LoadImage): Promise<LoadImageData> => {
    if (!image) {
      return Promise.reject();
    }

    if (imageCache.current[id] || imageDataCache.current[id]) {
      return Promise.resolve({ id, renderKey });
    }
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        if (renderCanvasCtxRef?.current) {
          const iconPosition = 10 * scale;
          renderCanvasCtxRef.current.drawImage(img, 0, 0, width, height);
          drawIcon({
            owner,
            saleAmount,
            scale,
            status,
            x: iconPosition,
            y: iconPosition,
          });
          imageDataCache.current[id] = renderCanvasCtxRef.current.getImageData(
            0,
            0,
            width,
            height,
          );
          renderCanvasCtxRef.current?.clearRect(0, 0, width, height);
        } else {
          imageCache.current[id] = img;
        }

        resolve({ id, renderKey });
      };
      img.crossOrigin = 'Anonymous';
      img.src = getDisplayImage(image);
    });
  };

  const cleanImageCache = ({ coords, maxGen }: ClearImageCacheArgs) => {
    coords.forEach(({ x, y }) => {
      for (let g = maxGen - 1; g >= 0; g--) {
        const key = `${x},${y},${g}`;
        // if (imageCache[key]) {
        //   delete imageCache[key];
        // }
        if (imageDataCache.current[key]) {
          delete imageDataCache.current[key];
        }
      }
    });
    canvasCacheRef.current = undefined;
  };

  const refreshImageCache = () => {
    // imageDataCache.current = {};
  };
  const addForceRenderSquare = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    ids.forEach(i => {
      forceDrawOnNextRender.current[i] = true;
    });
  };

  return {
    addForceRenderSquare,
    cacheScreen,
    cleanImageCache,
    drawImages,
    loadImage,
    refreshImageCache,
  };
};
