import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  getImagesByIndexes,
  indexToRowCol,
  rowColToIndex,
  vector2D,
  Vector2D,
} from './util';
import { useCanvasViewport } from './useCanvasViewport';
import { ClearImageCacheArgs, useImageRenderer } from './useImageRenderer';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openDialog } from 'redux/slices/dialog';
import { useSelector } from 'react-redux';
import { getNftData } from 'redux/slices/nft';
import useEventListener from '../useEventListener';
import { getCurrentHeight } from '../../redux/slices/application';
import usePageNavigation from '../usePageNavigation';
import { debounce } from 'lodash';

export type NavigateToIndexArgs = {
  index: number;
  openInfoDialog?: boolean;
};

export type CanvasManager = {
  addForceRenderSquare: (id: string | string[]) => void;
  cleanImageCache: (args: ClearImageCacheArgs) => void;
  init: (canvas: HTMLCanvasElement) => void;
  navigateToIndex: (args: NavigateToIndexArgs) => void;
  refreshImageCache: () => void;
  zoomOut: () => void;
  zoomIn: () => void;
};

export type OnRender = {
  origin: Vector2D;
};

export const CanvasContext = React.createContext<Omit<CanvasManager, 'init'>>({
  addForceRenderSquare: () => {},
  cleanImageCache: () => {},
  navigateToIndex: () => {},
  refreshImageCache: () => {},
  zoomIn: () => {},
  zoomOut: () => {},
});

export function useCanvasManager(): CanvasManager {
  const dispatch = useDispatch();
  const cacheTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const resizeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const mouseDownRef = useRef<boolean>(false);
  const startDragOriginRef = useRef<Vector2D>({ x: 0, y: 0 });
  const startDragMouseRef = useRef<Vector2D>();
  const isDraggingRef = useRef<boolean>(false);
  const originRef = useRef<Vector2D>({ x: 0, y: 0 });
  const [initialCacheComplete, setInitialCacheComplete] = useState(false);
  const [firstRenderComplete, setFirstRenderComplete] = useState(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector(getNftData);
  const currentHeight = useSelector(getCurrentHeight);
  const { navigateTo } = usePageNavigation();

  const {
    getIndexesInViewport,
    getScreenCoordsBounds,
    clampOrigin,
    getScale,
    imageHeight,
    imageIndexToOrigin,
    imageWidth,
    mousePositionToImageIndex,
    resolveScaleIndex,
    scaleIndex,
    zoomIn,
    zoomOut,
  } = useCanvasViewport({
    canvas,
    ctx,
    render,
  });

  const {
    addForceRenderSquare,
    cacheScreen,
    drawImages,
    cleanImageCache,
    refreshImageCache,
  } = useImageRenderer(ctx, canvas);

  function render() {
    if (ctx && canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const indexes = getIndexesInViewport(originRef.current);
      const imagesToRender = getImagesByIndexes({ data, indexes });

      drawImages({
        images: imagesToRender,
        origin: originRef.current,
        scale: getScale(),
      });
    }
  }

  useEffect(() => {
    if (searchParams.get('center')) {
      navigateToIndex({
        index: parseInt(searchParams.get('center')?.toString() ?? '0'),
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ctx && Object.keys(data).length) {
      render();
      if (!firstRenderComplete) {
        setFirstRenderComplete(true);
      }
    }
  }, [ctx, data, scaleIndex, currentHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (data && firstRenderComplete && !initialCacheComplete) {
  //     setTimeout(() => {
  //       const bounds = getScreenCoordsBounds(originRef.current);
  //       // cacheScreen(bounds, originRef.current);
  //       setInitialCacheComplete(true);
  //     }, 5000);
  //   }
  // }, [data, firstRenderComplete]);

  const handleSetOrigin = useCallback(
    (newOrigin: Vector2D) => {
      originRef.current = clampOrigin(newOrigin);
      render();

      if (cacheTimeoutRef?.current) {
        clearTimeout(cacheTimeoutRef?.current);
      }

      cacheTimeoutRef.current = setTimeout(() => {
        const bounds = getScreenCoordsBounds(originRef.current);
        cacheScreen(bounds, originRef.current);
      }, 1200);
    },
    [clampOrigin], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const handleResize = () => {
      if (resizeTimeoutRef?.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        const gallery = document.getElementById('gallery') as HTMLCanvasElement;
        if (gallery) {
          gallery.width = window.innerWidth;
          gallery.height = window.innerHeight;
          handleSetOrigin(originRef.current);
        }
      }, 300);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleSetOrigin]);

  const updateUrl = (index?: number, scale?: number) => {
    const centerIndex =
      index ??
      mousePositionToImageIndex({
        mousePosition: vector2D(window.innerWidth / 2, window.innerHeight / 2),
        origin: originRef.current,
      });

    navigate(
      `${location.pathname}?center=${centerIndex}&scale=${scale ?? scaleIndex}`,
      {
        replace: true,
      },
    );
  };

  const handleCanvasMouseDown = useCallback(
    (evt: MouseEvent) => {
      if (canvas) {
        startDragOriginRef.current = { ...originRef.current };
        startDragMouseRef.current = vector2D(evt.clientX, evt.clientY);
        mouseDownRef.current = true;
      }
    },
    [canvas],
  );

  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      if (mouseDownRef.current) {
        if (canvas) {
          canvas.style.cursor = 'grab';
        }
        isDraggingRef.current = true;
        handleSetOrigin(
          vector2D(
            evt.clientX -
              (startDragMouseRef.current?.x ?? 0) +
              (startDragOriginRef.current?.x ?? 0),
            evt.clientY -
              (startDragMouseRef.current?.y ?? 0) +
              (startDragOriginRef.current?.y ?? 0),
          ),
        );
      }
    },
    [handleSetOrigin],
  );

  const handleTouchMove = useCallback(
    (evt: TouchEvent) => {
      evt.preventDefault();
      handleMouseMove({
        clientX: evt.changedTouches[0].clientX,
        clientY: evt.changedTouches[0].clientY,
      } as MouseEvent);
    },
    [handleMouseMove],
  );

  const handleCanvasTouchStart = useCallback(
    (evt: TouchEvent) => {
      handleCanvasMouseDown({
        clientX: evt.changedTouches[0].clientX,
        clientY: evt.changedTouches[0].clientY,
        stopPropagation: evt.stopPropagation,
        preventDefault: evt.preventDefault,
      } as MouseEvent);
    },
    [handleCanvasMouseDown],
  );

  const handleCanvasMouseUp = useCallback(
    (evt: MouseEvent) => {
      if (canvas) {
        canvas.style.cursor = 'pointer';
        if (!isDraggingRef.current) {
          let index = mousePositionToImageIndex({
            mousePosition: vector2D(evt.clientX, evt.clientY),
            origin: originRef.current,
          });

          const { parent } = data?.[index] ?? {};

          if (parent && parent !== 'null') {
            const [x, y] = parent.split(',').map(n => parseInt(n, 10));
            index = rowColToIndex(y, x);
          }

          setTimeout(() => {
            const { row, col } = indexToRowCol(index);
            navigateTo(`/square/${col},${row}`);
            // dispatch(openDialog(index));
          });

          // navigateToIndex({ index });
        }
      }
    },
    [canvas, mousePositionToImageIndex], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCanvasTouchEnd = useCallback(
    (evt: TouchEvent) => {
      handleCanvasMouseUp({
        clientX: evt.changedTouches[0].clientX,
        clientY: evt.changedTouches[0].clientY,
      } as MouseEvent);
    },
    [handleCanvasMouseUp],
  );

  const handleWindowMouseUp = (evt: MouseEvent) => {
    if (canvas) {
      if (isDraggingRef.current) {
        updateUrl();
        const origin = vector2D(
          evt.clientX -
            (startDragMouseRef.current?.x ?? 0) +
            (startDragOriginRef.current?.x ?? 0),
          evt.clientY -
            (startDragMouseRef.current?.y ?? 0) +
            (startDragOriginRef.current?.y ?? 0),
        );

        handleSetOrigin(origin);
      }

      isDraggingRef.current = false;
      mouseDownRef.current = false;
    }
  };

  const handleWindowTouchEnd = (evt: TouchEvent) => {
    handleWindowMouseUp({
      clientX: evt.changedTouches[0].clientX,
      clientY: evt.changedTouches[0].clientY,
    } as MouseEvent);
  };

  useEventListener<MouseEvent>('mousedown', handleCanvasMouseDown, canvas);
  useEventListener<TouchEvent>('touchstart', handleCanvasTouchStart, canvas);
  useEventListener<MouseEvent>('mouseup', handleCanvasMouseUp, canvas);
  useEventListener<TouchEvent>('touchend', handleCanvasTouchEnd, canvas);
  useEventListener<MouseEvent>('mousemove', handleMouseMove, canvas);
  useEventListener<TouchEvent>('touchmove', handleTouchMove, canvas);
  useEventListener<MouseEvent>('mouseup', handleWindowMouseUp);
  useEventListener<TouchEvent>('touchend', handleWindowTouchEnd);

  const init = useCallback(
    (_canvas: HTMLCanvasElement) => {
      // @ts-ignore
      const canvasCtx = _canvas.getContext('2d', { alpha: false });
      if (!canvasCtx) {
        return null;
      }
      setCtx(canvasCtx);
      setCanvas(_canvas);
    },
    [setCtx, setCanvas],
  );

  function navigateToIndex({
    index,
    openInfoDialog = false,
  }: NavigateToIndexArgs) {
    const { x, y } = imageIndexToOrigin(
      index,
      vector2D(
        window.innerWidth / 2 -
          imageWidth() / 2 -
          (canvas?.getBoundingClientRect()?.x ?? 0),
        window.innerHeight / 2 -
          imageHeight() / 2 -
          (canvas?.getBoundingClientRect()?.y ?? 0),
      ),
    );

    updateUrl(index);

    handleSetOrigin(vector2D(x, y));

    if (openInfoDialog) {
      dispatch(openDialog(index));
    }
  }

  const handleScaleChange = (newScaleIndex: number) => {
    const newScale = getScale(newScaleIndex);
    const newWidth = imageWidth(newScale);
    const newHeight = imageWidth(newScale);
    const paramsCenter = parseInt(searchParams.get('center') ?? '0', 10);
    const newOrigin = imageIndexToOrigin(
      paramsCenter,
      vector2D(
        window.innerWidth / 2 -
          newWidth / 2 -
          (canvas?.getBoundingClientRect()?.x ?? 0),
        window.innerHeight / 2 -
          newHeight / 2 -
          (canvas?.getBoundingClientRect()?.y ?? 0),
      ),
      newWidth,
      newHeight,
    );

    // refreshImageCache();
    updateUrl(paramsCenter, newScaleIndex);

    handleSetOrigin(newOrigin);
  };

  return {
    addForceRenderSquare,
    cleanImageCache,
    init,
    navigateToIndex,
    refreshImageCache,
    zoomIn: () => {
      handleScaleChange(resolveScaleIndex(scaleIndex + 1));
      zoomIn();
    },
    zoomOut: () => {
      handleScaleChange(resolveScaleIndex(scaleIndex - 1));
      zoomOut();
    },
  };
}
