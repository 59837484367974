import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Gallery from 'components/Gallery/Gallery';
import WaitNotification from './components/Notifications/WaitNotification';
import logo from 'components/Global/logo.png';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import useWaves from './hooks/crypto/useWaves';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/application';
import PageDialogWrapper from './components/Pages/PageDialogWrapper';
import { CanvasContext, useCanvasManager } from './hooks/canvas';
import SocketProvider from './components/Sockets/SocketProvider';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Home = () => {
  const dispatch = useDispatch();
  const { getUserData } = useWaves();
  const {
    addForceRenderSquare,
    cleanImageCache,
    init,
    navigateToIndex,
    refreshImageCache,
    zoomIn,
    zoomOut,
  } = useCanvasManager();

  useEffect(() => {
    const getData = async () => {
      const user = await getUserData();
      dispatch(setUser(user));
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCanvasLoad = (canvas: HTMLCanvasElement) => {
    init(canvas);
  };

  const contextData = {
    addForceRenderSquare,
    cleanImageCache,
    navigateToIndex,
    refreshImageCache,
    zoomIn,
    zoomOut,
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      gap="2"
    >
      <Heading
        fontFamily="deadly_advanceregular"
        size="2xl"
        textShadow="1px 1px 10px #000000"
        width="min-content"
        letterSpacing="0.075"
        fontWeight="normal"
        mb="2"
      >
        Grid&nbsp;Gang
      </Heading>
      <Heading size="md">Down for maintenance</Heading>
      <Heading size="sm">We'll be back up shortly</Heading>
    </Box>
  );
};

export default Home;
