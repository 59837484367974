import { CoordsMatrix } from '../types/ui';
import { Bounds, vector2D, Vector2D } from '../hooks/canvas/util';
import { COLUMN_NUM, ROW_NUM } from '../hooks/canvas/constants';
import { colorSchemes, simpleColorSchemes } from '../constants';

type GetCoordsMatrix = {
  xSize: number;
  ySize: number;
  xStart: number;
  yStart: number;
};

export const getCoordsMatrix = ({
  xSize,
  ySize,
  xStart,
  yStart,
}: GetCoordsMatrix): CoordsMatrix => {
  const coords: CoordsMatrix = [];

  for (let y = yStart; y < yStart + ySize; y++) {
    const row: string[] = [];
    for (let x = xStart; x < xStart + xSize; x++) {
      row.push(`${x},${y}`);
    }
    coords.push(row);
  }

  return coords;
};

export type ClampBounds = {
  height: number;
  pos: Vector2D;
  totalHeight: number;
  totalWidth: number;
  width: number;
};
export const clampBounds = ({
  height,
  pos,
  totalHeight,
  totalWidth,
  width,
}: ClampBounds): Vector2D => {
  const maxX = -(COLUMN_NUM * width - (totalWidth ?? 0));
  const maxY = -(ROW_NUM * height - (totalHeight ?? 0));
  const x = pos.x > 0 ? 0 : pos.x < maxX ? maxX : pos.x;
  const y = pos.y > 0 ? 0 : pos.y < maxY ? maxY : pos.y;
  return vector2D(x, y);
};

export const clampGridNumber = (
  num: number,
  min: number = 0,
  max: number = COLUMN_NUM,
) => Math.min(Math.max(min, num), max);

type IsInBounds = {
  bounds: Bounds;
  point: Vector2D;
};

export const isInBounds = (point: Vector2D, bounds?: Bounds) => {
  if (!bounds) {
    return false;
  }
  const { tl, br } = bounds;
  return (
    point.x >= tl.x && point.x <= br.x && point.y >= tl.y && point.y <= br.y
  );
};

type SplitToNumbers = {
  data?: string;
  delimiter?: string;
};

export const splitToNumbers = ({ data, delimiter = ',' }: SplitToNumbers) => {
  if (!data) {
    return [];
  }

  return data.split(delimiter).map(n => parseInt(n));
};

export const getRandomColorScheme = (colors = simpleColorSchemes) =>
  colorSchemes?.[Math.round(Math.random() * colorSchemes.length - 1)] ?? 'cyan';

export const getDisplayImage = (image?: string) => {
  if (!image) {
    return '';
  }
  return image.replace('.io/', '.io/display/');
};
