import { InvokeScriptFunctionArgs } from './types';
import { DAPP_ADDRESS, WAVES } from './constants';
import { BASE_SQUARE_PRICE, WAVELET } from '../../constants';
import { getFullWaves } from '../../helpers/blockchain';
const WavesKeeper = (window as any).WavesKeeper;

export type BuildInvokeScriptParamsArgs = {
  dApp?: string;
  func: string;
  args?: InvokeScriptFunctionArgs[];
  paymentAmount?: number;
  paymentAssetId?: string | null;
};

export const buildInvokeScriptParams = ({
  dApp = DAPP_ADDRESS,
  func,
  args,
  paymentAmount = BASE_SQUARE_PRICE,
  paymentAssetId = null,
}: // @ts-ignore
BuildInvokeScriptParamsArgs): typeof window.TSignTransactionData.TSignTransactionData => ({
  type: 16,
  data: {
    dApp,
    call: {
      function: func,
      // @ts-ignore
      args: args ?? [],
    },
    payment: [{ assetId: paymentAssetId, tokens: paymentAmount }],
    fee: {
      tokens: '0.01',
      assetId: WAVES,
    },
  },
});

export const buildBurnParams = (
  assetId: string,
  // @ts-ignore
): typeof window.WavesKeeper.TSignTransactionData => ({
  type: 6,
  data: {
    assetId,
    amount: 1,
  },
});
